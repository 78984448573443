import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  api_url = environment.server_url;
  api_key = environment.api_key;
  constructor(public http: HttpClient) { }

  getBenefits(register){
    return this.http.get(this.api_url+'/benefits-list/'+register+'?api_key='+this.api_key);
  }
  getBenefit(benefit_id, enrolment_id){
    let payload = {
      "benefit_id":benefit_id,
      "enrolment_id":enrolment_id,
    };
    return this.http.post(this.api_url+'/getBenefitsArena?api_key='+this.api_key,payload);
  }
  registerBenefit(payload){

    return this.http.post(this.api_url+'/register-benefit?api_key='+this.api_key,payload);
  }


}
