import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrizeService {
  api_url = environment.server_url;
  api_key = environment.api_key;

  constructor(private http: HttpClient) { }

  getPrizes(){
    return this.http.get(this.api_url+'/available-prizes?api_key='+this.api_key);

  }
  
}
