import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule } from "@angular/common";


import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BenefitsService } from './services/benefits.service';
import { Modal1Component } from './pages/modal1/modal1.component';
import { Modal2Component } from './pages/modal2/modal2.component';
import { Modal3Component } from './pages/modal3/modal3.component';
import { Modal4Component } from './pages/modal4/modal4.component';
import { Modal5Component } from './pages/modal5/modal5.component';
import { Modal6Component } from './pages/modal6/modal6.component';
import { ModalHelpComponent } from './pages/modal-help/modal-help.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import '@capacitor-community/camera-preview'
@NgModule({
  declarations: [
    AppComponent,
    Modal1Component,
    Modal2Component,
    Modal3Component,
    Modal4Component,
    Modal5Component,
    Modal6Component,
    ModalHelpComponent,],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule, 
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    })],
  providers: [
      { provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy}, 
      BenefitsService,
      {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
