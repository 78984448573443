import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  api_url = environment.server_url;
  api_key = environment.api_key;

  constructor(public http: HttpClient) { }

  getResource(){
    return this.http.get(this.api_url+'/resources?api_key='+this.api_key);
  }
  
  getUser(enrolment) {
    return this.http.post(this.api_url+'/getUserByEnrolment?api_key='+this.api_key,enrolment);
  }
  attemp(enrolment) {
    return this.http.post(this.api_url+'/storeAttempForUser?api_key='+this.api_key,enrolment);
  }
}
