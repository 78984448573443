import { Component, OnInit, Input} from '@angular/core';
import { ModalController,AlertController } from '@ionic/angular';
import { BenefitsService } from 'src/app/services/benefits.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal3Component } from '../modal3/modal3.component';
import { Modal2Component } from '../modal2/modal2.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-modal1',
  templateUrl: './modal1.component.html',
  styleUrls: ['./modal1.component.scss'],
})
export class Modal1Component implements OnInit {
  @Input() benefit_id;
  @Input() enrolment_id;
  @Input() code;
  @Input() email;
  benefit:any  = {
              highlighted: " ",
              name: " ",
              image_url:" ",
              type:" ",
            }


  is_loaded:boolean=false;
  
  constructor(public alertCtrl: AlertController,public modalController: ModalController,private benefitsService: BenefitsService, private router: Router,private titleService: Title) {
    this.titleService.setTitle('Cinemex');
  }
  
  ngOnInit() {
    console.log(this.email);
    this.benefitsService.getBenefit(this.benefit_id,this.enrolment_id)
    .subscribe(benefit => {
      this.benefit = benefit;
      this.is_loaded=true;
      }
    );
  }
  
  
  registerBenefit() {
    let payload = {
      benefit_id:this.benefit_id,
      enrolment_id:this.enrolment_id
    }
    this.benefitsService.registerBenefit(payload)
    .subscribe((register_details:any) => {
        if (register_details.code=="201") {
          this.modalController.dismiss();
          window.location.reload();
        }
        
      }
    );
  }

  async openmodalganador(register_details){
  const modal = await this.modalController.create({
    component: Modal3Component,
    componentProps: { 
      week_id: register_details.details.week_id,
      registration_number: register_details.details.egistration_number,
      prize: register_details.details.prize,
      description: register_details.details.description,
      code: register_details.details.code,
      image_url: register_details.details.image_url,
    },
    backdropDismiss: false,
    cssClass: 'benefit-css'
    
  });

  return await modal.present();
} 

  async openmodalnoganador(){
    
    const modal = await this.modalController.create({
      component: Modal2Component,
      backdropDismiss: false,
      cssClass: 'participating-css'
      
    });
   
    return await modal.present();
  }

  cerrarModal(){
    this.modalController.dismiss();
  }
  async presentAlertConfirm() {
    
    const alert = await this.alertCtrl.create({
      backdropDismiss:false,
      header: 'IMPORTANTE',
     
      message: '<strong> En el momento de ACEPTAR el canje de este cupón, se enviará un mail al correo '+this.email+' y quedará automáticamente eliminado en ese momento.</strong>',
      buttons: [
        {
          text: 'Regresar',
          role: 'cancel',
          handler: (blah) => {
            this.cerrarModal()
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            this.presentSuccessfulRegistration()
           //this.registerBenefit() 
           
          }
        }
      ]
    });

  await alert.present();
  
  }

  async presentSuccessfulRegistration() {
    
    const alert = await this.alertCtrl.create({
      backdropDismiss:false,
      header: '',
      message: '<strong>¡Registro Exitoso!</strong>',
      buttons: []
    });
    await alert.present();
    setTimeout(()=>{
      alert.dismiss();
      this.registerBenefit() 
    }, 2000);


  }
  openNewTab(url) {
    window.open(url, '_blank');
  }
  
}