import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PrizeService } from 'src/app/services/prize.service';
import { Modal6Component } from '../modal6/modal6.component';


@Component({
  selector: 'app-modal4',
  templateUrl: './modal4.component.html',
  styleUrls: ['./modal4.component.scss'],
})
export class Modal4Component implements OnInit {
  image_url="hola.jpg"
  prizes:any=[]
  prize_id:any=-1;

  constructor(private PrizeService: PrizeService, public modalController: ModalController) { }

  ngOnInit() {

    this.PrizeService.getPrizes()
    .subscribe(prizes => {
      this.prizes = prizes;
      }
    );
  }

  async openModal6(prize){
    
    const modal = await this.modalController.create({
      component: Modal6Component,
      componentProps: { 
        prize_id: prize.prize_id,
        highlighted: prize.highlighted,
        image_url:prize.image_url,
        name:prize.name,
        description:prize.description,
        
      },
      backdropDismiss: false,
      cssClass: 'benefit-css'
    });
    
    return await modal.present();
  }
}
  

