import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-modal2',
  templateUrl: './modal2.component.html',
  styleUrls: ['./modal2.component.scss'],
})
export class Modal2Component implements OnInit {
  @Input()  thank_you_body;
  @Input()  thank_you_title;
  resources: any  = {
            image_bottom: " ",
            thank_you_body: " ",
            thank_you_title: " ",
            type_slide_1:" ",
            type_slide_2:" ",
            type_slide_3:" ",
  }

 

  constructor(private router: Router, public modalController: ModalController,private titleService: Title,private ResourcesService: ResourcesService) {
    this.titleService.setTitle('Registro completado');
  }

  ngOnInit() {
    this.ResourcesService.getResource()
    .subscribe(resources_list => {
      this.resources = resources_list;
      }
    );
  }

  cerrarModal(){
    this.modalController.dismiss();
    this.router.navigate(['./compra/ganadores'])
  }
}
