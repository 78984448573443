import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PrizeService } from 'src/app/services/prize.service';

@Component({
  selector: 'app-modal6',
  templateUrl: './modal6.component.html',
  styleUrls: ['./modal6.component.scss'],
})
export class Modal6Component implements OnInit {
  @Input()  prize_id;
  @Input()  highlighted;
  @Input()  image_url;
  @Input()  name;
  @Input()  description;
  
  prizes:any=[];
 
  constructor(private PrizeService: PrizeService,public modalController: ModalController) { }

  ngOnInit() {
    this.PrizeService.getPrizes()
    .subscribe(prize_list => {
      this.prizes = prize_list;
      }
    );
  }
  cerrarModal(){
    this.modalController.dismiss();
  }

}
