import { Component, OnInit, Input} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal3',
  templateUrl: './modal3.component.html',
  styleUrls: ['./modal3.component.scss'],
})
export class Modal3Component implements OnInit {
  @Input() week_id;
  @Input() registration_number;
  @Input() prize;
  @Input() description;
  @Input() code;
  @Input() image_url;
  constructor(private router: Router, public modalController: ModalController,private titleService: Title) { 
    this.titleService.setTitle('Registro incompletado');

  }

  ngOnInit() {}

  cerrarModal() {
    this.modalController.dismiss();
    this.router.navigate(['./mascrema/home'])
  }
}
